@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.contactFormWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;

    @include responsive.media('>tablet') {
        max-width: 25rem;
    }
}

.contactForm {
    display: flex;
    flex-direction: column;
}

.formGroup {
    @include mixins.form-grid();
}

.contactFormHeading {
    font-size: 1rem;
    font-weight: vars.$font-weight--medium;
    text-transform: uppercase;
}

.contactFormDescription {
    font-size: 0.875rem;
}
