@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    border: 2px solid colors.$border-color--primary;
    border-radius: 0.375rem;
    padding: 2rem;
}

.root_editMode {
    composes: root;
    border: none;
    border-bottom: 2px solid colors.$border-color--primary;
    border-radius: 0px;
    padding: 0;
}

.cardHeader {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    place-content: center;
}

.cardTitle {
    font-weight: fonts.$font-weight--bold;
}

.editButton {
    align-items: center;
    display: flex;
    font-size: 0.85rem;
    gap: 0.5rem;

    &Text {
        @include responsive.media('<=tablet') {
            @include mixins.a11y-visually-hidden();
        }
    }
}

.cardWrapper {
    @include mixins.card();
}

.editWrapper {
    display: grid;
}

.checkoutStepSubtitle {
    @include mixins.checkoutStepSubtitle();
}

.checkoutStepTitle {
    @include mixins.checkoutStepTitle();
}

.editIcon {
    height: 1rem;
}

.successIcon {
    @include mixins.success-icon();
}
