@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;

.root {
    align-items: flex-start;
    border-radius: variables.$border-radius;
    color: colors.$notification-text-color;
    display: flex;
    gap: 0.5rem;
    grid-column: 1 / -1; // in case it's used in a form, make sure it spans fully;
    justify-content: center;
    padding: 0.75rem 1rem;
    text-align: center;

    a {
        @include mixins.link-underline($baseColor: currentColor, $hoverColor: currentColor, $hasUnderline: true);
    }
}

.error {
    composes: root;
    background-color: colors.$notification-background-color--error;
    color: colors.$notification-text-color--error;
}

.default {
    composes: root;
    background-color: colors.$notification-background-color--default;
    color: colors.$notification-text-color--default;
}

.success {
    composes: root;
    background-color: colors.$notification-background-color--success;
    color: colors.$notification-text-color--success;
}

.notificationContent {
    display: grid;
    gap: 1rem;
    place-items: flex-start;
    text-align: left;
    width: 100%;
}

.notificationIcon {
    height: 1.5rem;
    width: 1.5rem;
}

.notificationIconError {
    composes: notificationIcon;
    color: colors.$notification-icon-color--error;
}

.notificationIconDefault {
    composes: notificationIcon;
    color: colors.$notification-icon-color--default;
}

.notificationIconSuccess {
    composes: notificationIcon;
    color: colors.$notification-icon-color--success;
}

.notificationModal {
    align-content: center;
    height: 100%;
    left: -100vw;
    opacity: 0;
    padding: 1rem;
    position: fixed;
    top: 0;
    transform: translate3d(
        -50%,
        0,
        0
    ); // If you find yourself in a situation to try and fix blurred out fonts, or borders, don't even bother, you will not win, but translate is the culprit.
    visibility: hidden;
    width: 100%;
    z-index: zIndex.$index--modal;
}

.notificationModalActive {
    composes: notificationModal;
    display: grid;
    left: 50%;
    opacity: 1;
    place-items: center;
    visibility: visible;
}

.notificationModalContent {
    background-color: colors.$color--white;
    border-radius: variables.$border-radius;
    box-shadow: variables.$card--box-shadow;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    height: 100%;
    overflow: hidden;
    padding: 5rem 1.5rem;
    place-content: flex-start;
    text-align: center;
    width: 100%;
    z-index: zIndex.$index--modal-content;

    @include responsive.media('>tablet') {
        max-height: 90vh;
        padding: 6rem 1.5rem;
        place-content: stretch;
        width: min(30rem, 100%);
    }
}

.notificationModalIcon {
    @include mixins.shimmer();
    margin: 0 auto 3rem;
}
