@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/z-index' as zIndex;

.radioInput {
    display: none;

    &:checked + .label {
        z-index: zIndex.$index--payment-method-selected;

        .addressIcon::before {
            transform: scale(1);
        }
    }
}

.label {
    align-items: flex-start;
    cursor: pointer;
    display: grid;
    font-size: 0.875rem;
    grid-gap: 1rem;
    grid-template-columns: 1.25rem 1fr auto;
    padding: 0.5rem 1rem;
    position: relative;
    transition: 0.2s;
}

.addressIcon {
    align-items: center;
    border-color: colors.$border-color--dark;
    border-radius: 50%;
    border-width: 1px;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    margin-top: 0.2rem;
    transition: 0.2s;
    width: 1.25rem;

    &::before {
        background-color: colors.$radio-button-background-color--success;
        border-radius: 50%;
        content: '';
        height: 0.5rem;
        position: absolute;
        transform: scale(0);
        transition: 0.2s;
        width: 0.5rem;
    }
}
