@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/fonts';

.information {
  display: grid;
}

.nameString {
  font-weight: fonts.$font-weight--bold;
}

.information,
.address {
  font-size: 0.875rem;
}

.address {
  display: grid;
  padding-top: 1.125rem;
}
