@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

$table-border: 1px solid colors.$border-color--tertiary;

.tableLink {
    @include mixins.link-underline($baseColor: colors.$text-color--red, $hasUnderline: true);
}

.tableCaption {
    @include mixins.a11y-visually-hidden();
}

.actionButton {
    color: colors.$color--electric-red;
    text-decoration: underline;

    &:hover {
        color: colors.$color--free-speech-red;
    }
}

.provider {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    gap: 0.5rem;
}

.table {
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(3, 1fr);
}

.rowTitle {
    display: none;
}

.rowCell {
    border-bottom: $table-border;
    padding: 0.75rem 1rem;

    &Provider {
        @extend .rowCell;
        background-color: colors.$brand-color--quaternary;
        border: $table-border;
        border-radius: 0.25rem 0.25rem 0 0;
        display: flex;
        grid-column: 1/4;
        justify-content: center;
        margin-top: 1.5rem;
        text-transform: uppercase;
    }

    &Title {
        @extend .rowCell;
        border-left: $table-border;
        border-right: $table-border;
        font-weight: fonts.$font-weight--bold;
        grid-column: 1/2;
        text-transform: uppercase;

        &Action {
            @extend .rowCellTitle;
            border-bottom-left-radius: 0.25rem;
        }
    }

    &Status {
        @extend .rowCell;
        border-right: $table-border;
        grid-column: 2/4;
    }

    &Action {
        @extend .rowCell;
        border-bottom-right-radius: 0.25rem;
        border-right: $table-border;
        grid-column: 2/4;
    }

    &:nth-child(4) {
        margin-top: 0;
    }
}

@include responsive.media('>tablet') {
    .table {
        border: $table-border;
        border-radius: variables.$border-radius;
    }

    .rowTitle {
        @include mixins.heading-4;
        background-color: colors.$brand-color--quaternary;
        border-bottom: $table-border;
        display: block;
        padding: 0.75rem 1rem;
        width: 100%;
    }

    .rowCell {
        &Provider {
            @extend .rowCell;
            background-color: colors.$color--white;
            border-left: none;
            border-right: none;
            border-top: none;
            display: block;
            grid-column: 1/2;
            justify-content: left;
            margin-top: 0;
            text-transform: none;
        }

        &Status {
            @extend .rowCell;
            border-right: none;
            grid-column: 2/3;
        }

        &Title {
            border-left: none;
            border-right: none;
            display: none;
        }

        &Action {
            @extend .rowCell;
            border-right: none;
            grid-column: 3/3;
        }

        &:nth-last-child(-n + 5) {
            border-bottom: none;
        }
    }
}
