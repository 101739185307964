@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';

.heading {
    font-size: 0.8rem;
    font-weight: fonts.$font-weight--medium;
    padding-bottom: 0.3rem;
}

.content {
    color: colors.$text-color--gray;
}
