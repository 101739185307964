@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;

.loqateInput {
    position: relative;
}

.loqateSuggestionsList {
    background-color: colors.$color--white;
    border-bottom-left-radius: variables.$border-radius;
    border-bottom-right-radius: variables.$border-radius;
    box-shadow: variables.$toast--box-shadow;
    max-height: 25rem;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    overflow-y: scroll;
    padding: 1rem;
    position: absolute;
    scrollbar-width: none; /* Firefox */
    width: 100%;
    z-index: zIndex.$index--overlay;

    &::-webkit-scrollbar {
        /* WebKit */
        display: none;
    }
}

.loqateSuggestion {
    color: colors.$message-text;
    cursor: pointer;
    line-height: 2rem;
}
