@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';

.orderSummary {
    display: grid;
    font-size: 0.875rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    opacity: 1;
    transition: opacity 0.2s;
}

.orderSummaryLoading {
    composes: orderSummary;
    opacity: 0.5;
}

.stockStatusMessageContainer {
    grid-column-end: span 2;
}

.stockStatusMessageContainer:empty {
    display: none;
}

.orderSummaryRow, .orderTotal {
    align-content: center;
    display: flex;
    justify-content: space-between;
}

.orderSummarySaleRow {
    padding-left: 1rem;
}

.orderSummaryTotalRow {
    align-items: center;
    display: inline-flex;
    font-weight: fonts.$font-weight--medium;
    gap: 0.5rem;
    padding-left: 0;
}


.price {
    text-align: right;
}

.body {
    flex-grow: 1;
    margin: 1rem 0;
    overflow-y: auto;
}

.summary {
    background-color: colors.$color--gallery;
    display: grid;
    flex-shrink: 0;
    gap: 0.5rem;
    margin: -0.75rem;
    padding: 1rem 0.75rem;
}

.emptyCart {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 26vh;
    padding: 0 2rem;

    @include responsive.media('>tablet') {
        padding: 0 5rem;
    }
}

.emptyMessage {
    font-size: 1.5rem;
    font-weight: fonts.$font-weight--medium;
    text-align: center;
}

.iconWrapper {
    color: colors.$color--gray-lightest;
}

.emptyCartIcon {
    color: currentColor;
    height: 9rem;
    width: 7rem;
}

:global(#hh) {
    .checkoutButton, .editCartButton {
        font-size: 1rem;
    }
}
