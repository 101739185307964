@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndex;

.root {
    display: none;
}

.rootVisible {
    background-color: colors.$color--white;
    box-shadow: variables.$card--box-shadow;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    max-width: 25rem;
    padding: 2rem 1.5rem;
    position: fixed;
    z-index: zIndex.$index--modal;
}

.positionCenter {
    left: 1.5rem;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    @include responsive.media('>=tablet') {
        left: 50%;
        right: unset;
        transform: translateX(-50%) translateY(-50%);
    }
}

.positionBottomRight {
    composes: positionCenter;

    @include responsive.media('>desktop') {
        bottom: 6.25rem;
        left: unset;
        right: 1rem;
        top: unset;
        transform: unset;
    }
}

.positionTopRight {
    composes: positionCenter;

    @include responsive.media('>desktop') {
        bottom: unset;
        left: unset;
        right: 1rem;
        top: 2rem;
        transform: unset;
    }
}

.heading {
    @include mixins.heading-3();

    font-weight: fonts.$font-weight--bold;
    text-transform: none;
}

button.closeButton {
    @include mixins.trigger-icon-no-background();

    position: absolute;
    right: 0;
    top: 0;
}
