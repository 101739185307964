@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

.triggerContainer {
    align-items: center;
    display: grid;
}

.trigger {
    align-items: center;
    color: colors.$text-color--secondary;
    display: flex;
    font-size: 0.75rem;
    height: inherit;
    position: relative;
}

.triggerWrapper {
    display: flex;
}

.counter {
    background-color: colors.$color--black;
    border-radius: 50%;
    color: colors.$text-color--secondary;
    display: grid;
    font-weight: fonts.$font-weight--medium;
    height: 1.25rem;
    overflow: hidden;
    place-items: center;
    position: absolute;
    right: -0.625rem;
    top: -0.625rem;
    width: 1.25rem;

    [data-theme='ww'] & {
        background-color: colors.$color--alizarin-crimson;
    }
}

.iconWrapper {
    position: relative;
}

.icon {
    height: 1.25rem;
    width: 1.25rem;
}

.subtotal {
    display: none;
    min-width: 4.5rem;
}

@include responsive.media('>desktop', 'pointer-fine') {
    .subtotal {
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;
        margin-left: 1rem;
        text-align: left;

        &Value {
            font-weight: fonts.$font-weight--bold;
            white-space: nowrap;
        }
    }

    .label {
        white-space: nowrap;
    }

    .icon {
        height: 1.625rem;
        width: 1.625rem;
    }
}

// White header design
@include whiteHeader.white-header-media{
    .trigger {
        color: var(--color--gray-700, colors.$color--mine-shaft);
    }
}

.visuallyHidden {
    @include mixins.a11y-visually-hidden();
    margin: 0 !important;
}
