@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    border-bottom: 1px solid colors.$border-color--primary;
    color: colors.$text-color--primary;
    display: grid;
    gap: 1.5rem;
    justify-items: stretch;
    padding-bottom: 2rem;
}

.notificationCallToAction {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
}

.resendConfirmationEmailPageRedirectButton {
    color: colors.$text-color--primary;
    width: 100%;

    @include responsive.media('>tablet') {
        width: auto;
    }
}

.title {
    @include mixins.heading-1();
    text-align: center;
}

.form {
    @include mixins.form-grid();
}

.headText {
    color: colors.$text-color--gray;
    margin-bottom: 1rem;
    text-align: center;
}

.buttonsContainer {
    display: grid;
    gap: 1.5rem;
    grid-auto-flow: row;
    width: 100%;
}

.forgotPasswordButtonContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    justify-self: start;
    text-align: center;
}

.forgotPasswordLink,
.createAccountButton {
    @include mixins.link-underline();
}

.createAccountLabel {
    padding-right: 0.25rem;
}

.errorNotification {
    margin-bottom: 2rem;
}

.grecaptchaBadge {
    visibility: hidden;
}
