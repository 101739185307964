@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.root {
    display: grid;
    gap: 2rem;
    width: 100%;
}

.formGroup {
    @include mixins.form-grid();
}

.tooltipTitle {
    @include mixins.heading-2();
}

.checkoutMessage {
    font-size: 0.75rem;
    margin: 0.5rem 0;
}

.checkoutStepSubtitle {
    @include mixins.checkoutStepSubtitle();
    font-weight: normal;
}

.subtitleWrapper {
    align-items: center;
    display: flex;
    gap: 0.5rem;
}

.checkoutMessageInactive {
    display: none;
}

.emailFieldNotice {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    svg {
        stroke: colors.$notification-text-color--success;
    }
}

.buttons {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    margin-bottom: 1px;
    padding-top: 2rem;
}

.submitAddressButton {
    font-size: 1rem;
}

.ButtonsContainer {
    align-items: center;
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: start;
}

.forgotPasswordLink {
    @include mixins.link-underline();
}
