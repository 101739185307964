@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.addressCard {
    width: 100%;

    @include responsive.media('>desktop-xl') {
        width: 50%;
    }
}

.cardTitle {
    max-width: 200px;
    width: 100%;
}
