@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/colors';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

:global(#hh) {
    .root {
        padding: 0;

        svg {
            height: 1.25rem;
            width: 1.25rem;
        }

        @include responsive.media('>tablet', 'pointer-fine') {
            display: none;
        }
    }

    .visuallyHidden {
        @include mixins.a11y-visually-hidden();
        margin: 0;
    }

    // White header design

    @include whiteHeader.white-header-media{
                .root {
                    background: none;
                    border: 0;
                    color: var(--color--gray-800, colors.$color--mine-shaft);
                }
    }
}
