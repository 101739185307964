@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.root {
    @include mixins.form-grid();
}

.buttonContainer {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    text-align: center;
}

.grecaptchaBadge {
    height: 0;
    visibility: hidden;
}
