@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.checkoutStepSubtitle {
    @include mixins.checkoutStepSubtitle();
}

.itemsReviewContainer {
    @include mixins.card();
    display: block;
    margin-top: 1rem;
    overflow: hidden;
    padding: 0;
    word-break: normal;
}
