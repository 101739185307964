@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';

.table {
    color: colors.$text-color--gray;
    width: 100%;
}

.tableCaption {
    border-bottom: 1px solid colors.$border-color--tertiary;
    color: colors.$text-color--primary;
    font-size: 1.1rem;
    font-weight: fonts.$font-weight--medium;
    margin-bottom: 0.1rem;
    padding-bottom: 0.5rem;
    text-align: left;
    text-transform: uppercase;
}

.tableRow {
    display: flex;
    justify-content: space-between;
}

.tableCell {
    padding: 0.4rem 0;
    vertical-align: top;

    &:last-child {
        padding-left: 0.4rem;
        text-align: right;
    }
}

.totalsPriceSaleLabel {
    align-items: center;
    display: inline-flex;
    gap: 0.5rem;
}

.totalsPriceSale, .totalsPriceSaleSummary {
    color: colors.$color--free-speech-red;
    text-align: right;
    white-space: nowrap;
}

.totalsPriceOrder {
    border-top: 1px solid colors.$border-color--tertiary;
    font-size: 1rem;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
}

.totalsPriceCoupon {
    display: flex;
}

.totalsPriceCouponLabel {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.discountBoxOpen {
    .discountBoxIcon {
        transform: rotate(180deg);
    }

    .totalsPriceSaleSummary {
        display: none;
    }
}

.discountBoxButton {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:focus {
        outline: none;
    }
}

.discountBoxIcon {
    transition: transform 0.2s;
}

.discountBoxContent {
    display: grid;
    gap: 0.5rem;
    padding: 0.5rem 0 0 1rem;
}

.discountBoxRow {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
}

.orderTotalRow {
    border-top: 1px solid colors.$border-color--tertiary;
    font-size: 1rem;
    font-weight: vars.$font-weight--medium;
    margin-top: 0.1rem;
    padding-top: 0.1rem;
}

@include responsive.media('>tablet', 'pointer-fine') {
    .root {
        margin-left: auto;
        width: 22rem;
    }
}
