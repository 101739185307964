@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

:global(#hh) {
    .addressBookWrapper {
        @include responsive.media('>tablet') {
            min-height: 70vh;
            padding-top: 2rem;
        }
    }

    .addressBookContainer {
        display: grid;
        grid-template-areas:
        'title'
        'addresses'
        'buttons';
        padding: 0;
        row-gap: 2rem;

        @include responsive.media('>tablet') {
            grid-template-areas:
            'title buttons'
            'addresses addresses';
        }
    }

    .pageTitle {
        @include mixins.heading-1();
        line-height: 1.2;
    }

    .content {
        @include responsive.media('>tablet') {
            grid-column: span 2;
        }
    }

    .buttonsContainer {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        grid-area: buttons;

        @include responsive.media('>tablet') {
            align-items: center;
            flex-direction: row;
            justify-content: right;
        }
    }

    .masonry {
        display: grid;
        gap: 1.5rem;

        @include responsive.media('>mobile') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include responsive.media('>tablet') {
            grid-template-columns: repeat(3, 1fr);
        }

        @include responsive.media('>desktop') {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
