@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';

.root {
    background-color: colors.$brand-color--secondary;
    font-size: 0.75rem;
    font-weight: fonts.$font-weight--bold;
    padding: 0.25rem;
    text-align: center;

    a {
        @include mixins.link-underline(
            $baseColor: colors.$text-color--red,
            $hoverColor: colors.$text-color--red,
            $hasUnderline: true
        );
    }
}
