@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';

.heading {
    @include mixins.heading-3();
    margin-bottom: 1rem;
}

.orderHistoryTable {
    border-bottom: 0.15rem solid colors.$border-color--light;
    color: colors.$text-color--gray;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;
    width: 100%;
}

.orderHistoryTableCaption {
    @include mixins.a11y-visually-hidden;
}

.orderHistoryTableBody {
    position: relative;
}

.orderHistoryTableRow {
    border-top: 0.05rem solid colors.$border-color--light;
    display: grid;
    gap: 0.8rem;
    padding: 0.8rem 0;
}

.orderHistoryTableCell {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    text-align: right;
    vertical-align: top;

    &::before {
        content: attr(data-th);
        font-size: 0.75rem;
        font-weight: fonts.$font-weight--medium;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;
        width: 50%;
    }
}

.orderHistoryTableCellActionWrapper {
    display: flex;
    gap: 0.8rem;
    justify-content: flex-end;
}

.orderHistoryTableCellAction {
    composes: orderHistoryTableCell;
    text-align: right;
}

.orderHistoryTableHead {
    color: colors.$text-color--primary;
    display: none;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.orderHistoryTableHeadRow {
    border-bottom: 0.05rem solid colors.$border-color--light;
}

.orderHistoryTableHeadCell {
    font-weight: fonts.$font-weight--medium;
    padding: 0.5rem 0.5rem 0.5rem 0;
    text-align: left;

    &:last-child {
        padding-right: 0;
        text-align: right;
    }
}

.orderHistoryTableLink {
    @include mixins.link-underline($baseColor: colors.$text-color--red, $hasUnderline: true);
    text-align: right;

    &Disabled {
        cursor: default;
        pointer-events: none;
        text-align: right;
        touch-action: none;
    }
}

@include responsive.media('>tablet', 'pointer-fine') {
    .orderHistoryTableHead {
        display: table-header-group;
    }

    .orderHistoryTableRow {
        border: 0;
        display: table-row;
    }

    .orderHistoryTableCell {
        display: table-cell;
        padding: 0.5rem 0.5rem 0.5rem 0;
        text-align: left;

        &::before {
            content: '';
        }

        &:last-child {
            padding-right: 0;
            text-align: right;
        }
    }
}
