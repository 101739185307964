@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.inputWithNoticeWrapper {
    display: grid;
    gap: 0.5rem;
}

.halfWidthContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include responsive.media('>tablet') {
        flex-direction: row;

        & > span {
            width: 50%;
        }
    }
}

.addressCardListContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include responsive.media('>desktop-xl') {
        flex-direction: row;
    }
}
