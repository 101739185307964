@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/utils/white-header' as whiteHeader;
@use '@/styles/resources/z-index' as zIndex;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.header {
    background-color: colors.$brand-color--tertiary;
    position: sticky;
    top: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    width: 100%;
    z-index: zIndex.$index--header;

    @include mixins.no-print();

    @include responsive.media('<=tablet') {
        top: var(--header-mobile-height);

        &Invisible {
            top: -20rem;
        }

        &SearchOpen {
            top: 0;
        }
    }
}

.headerLogo {
    fill: colors.$color--white;
}

.secondaryActions {
    display: flex;
    z-index: zIndex.$index--header-secondary-actions;

    > * {
        margin-left: 2rem;
    }
}

.headerLogoWrapper {
    align-items: center;
    display: flex;
}

.headerLogoWrapper,
.megaMenu,
.secondaryActions {
    transition-delay: 0.2s;
    transition-duration: 224ms;
    transition-property: opacity, filter;
    transition-timing-function: ease-in-out;
}

.secondaryActionsSearchOpen {
    display: none;
}

.infoPanel {
    box-sizing: border-box;
    color: colors.$color--white;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    justify-content: center;
    overflow: hidden;
    text-align: center;

    @include responsive.media('>tablet', 'pointer-fine') {
        display: flex;
        justify-content: space-between;
    }

    @include responsive.media('>desktop', 'pointer-fine') {
        flex-wrap: nowrap;
    }
}

.mainTopBar {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    @include responsive.media('>tablet', 'pointer-fine') {
        margin-bottom: 0;
    }

    @include responsive.media('>desktop', 'pointer-fine') {
        border-bottom: none;
    }
}

.headerWrapper {
    background-color: var(--brand-color--primary, colors.$brand-color--primary);
}

.contentWrapper {
    display: flex;
    min-height: 3rem;

    @include responsive.media('>tablet', 'pointer-fine') {
        min-height: 3.875rem;
        padding-bottom: 0;
        padding-top: 0;
    }
}

.contentContainer {
    background-color: var(--brand-color--primary, colors.$brand-color--primary);
    box-sizing: border-box;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
}

// White header design
@include whiteHeader.white-header-media {
    .headerWrapper {
        background-color: var(--color-white, #fff);
        box-shadow: 0 0 0 1px var(--color--gray-200, colors.$color--wild-sand);
    }

    .headerLogo {
        background: var(--brand-color--primary, colors.$brand-color--primary);
        height: 35px;
        padding: 14% 1% 14% 12%;
        width: 51px;
    }

    .contentContainer {
        background-color: transparent;
    }
}
