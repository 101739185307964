@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.root {
    @include mixins.form-grid();
    margin: 2rem auto;
    padding: 0 1rem;
    width: min(32rem, 100%);
}

.title {
    @include mixins.heading-1();
    line-height: 1.2;
    text-align: center;
}
