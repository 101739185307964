@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/z-index';
@use '@/styles/resources/variables';

:global(#hh) {
    .root {
        display: grid;
        gap: 1.5rem;
        margin-bottom: 2rem;
        padding: 0;

        @include vars.mq-medium-up {
            grid-template-areas:
                'gallery details'
                'description details';
            grid-template-columns: 1fr 1fr;
        }
    }

    .productPreview {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
    }

    .imageCarousel {
        @include vars.mq-medium-up {
            grid-area: gallery;
        }
    }

    .productInfo {
        box-sizing: content-box;
        display: grid;
        height: min-content;
        padding: 0 1rem 1rem;
        z-index: z-index.$index--product-details-segment;

        @include vars.mq-medium-up {
            grid-area: details;
            max-width: 29.75rem;
            padding-top: 2rem;
            position: sticky;
            top: 6rem;
        }

        @include vars.mq-large {
            padding: 2rem 2.25rem 0;
        }
    }

    .productInfoTitle {
        display: grid;
        gap: 0.5rem;
        padding-bottom: 0.5rem;

        @include vars.mq-medium-up {
            padding-bottom: 2.5rem;
        }
    }

    .productInfoTitleMobile {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem 0.75rem;

        @include vars.mq-medium-up {
            display: none;
        }
    }

    .productInfoTitleDesktop {
        @include vars.mq-small {
            display: none;
        }
    }

    .productDetailsContainer {
        margin-right: 0;
        max-width: variables.$max-screen-width / 2;
    }

    .productInfoContainer {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .paypalMessage iframe {
        display: block;
        margin: 0.75rem 0 0;
        @include vars.mq-medium-up {
            margin: 1rem 0 0;
        }
    }

    .klarnaMessage {
        margin-top: 0.75rem;
        @include vars.mq-medium-up {
            margin-top: 1rem;
        }
    }

    .options {
        display: grid;
        gap: 2.5rem;

        img {
            aspect-ratio: 1 / 1;
            object-fit: contain;
        }
    }

    .productName {
        color: var(--color--black);
    }

    .productPrice {
        color: var(--color--black);
        font-weight: vars.$font-weight--medium;
        gap: 0.5rem;

        @include vars.mq-medium-up {
            gap: 1rem;
        }
    }

    .quantityTitle {
        align-items: center;
        display: flex;
        font-size: 0.875rem;
        font-weight: fonts.$font-weight--bold;
    }

    .productSection {
        font-size: 0.875rem;
        grid-column: auto;
    }

    .productSectionTitle {
        font-size: 1.1rem;
        margin-bottom: 1rem;
        text-transform: uppercase;

        @include vars.mq-medium-up {
            font-size: 1.5rem;
        }
    }

    .productDeliveryDate {
        margin-top: 0.25rem;
        @include vars.mq-medium-up {
            margin-top: 0.5rem;
        }
    }

    .yotpoFitSize {
        display: flex;
        gap: 0.25rem;

        &Container {
            padding-top: 0.875rem;
        }
    }

    .productInfoReview {
        display: flex;
        flex-wrap: wrap;
        gap: 0.3rem;
    }

    .productInfoReviewText {
        + a {
            text-transform: lowercase;
        }
    }

    .productInfoActions {
        align-items: center;
        display: flex;
        gap: 0.5rem;
        padding-top: 2.5rem;
    }

    .productNotification {
        margin-bottom: 1rem;

        @include vars.mq-medium-up {
            margin-bottom: 2rem;
        }
    }

    .productToolbar {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: space-between;
    }

    .productToolbarLink {
        align-items: center;
        display: inline-flex;
        gap: 0.5rem;
        @include mixins.link-underline(
            $baseColor: colors.$color--gray-darker,
            $hoverColor: colors.$color--gray-darker,
            $hasUnderline: true
        );

        svg {
            height: 1.2rem;
            width: 1.1rem;

            path {
                stroke: currentColor;
            }
        }
    }
}
