@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'sass:math';
@use '@/styles/resources/colors';

:global(#hh) {
    .root {
        cursor: initial;
        max-width: 3.25rem;
        padding: 0;
        transition-duration: 0.16s;
        transition-timing-function: ease-in-out;
        width: 100%;
    }

    .rootIsActive {
        cursor: pointer;
    }

    .addToListButtonIcon {
        color: currentColor;
        fill: none;
        height: 1.1rem;
        stroke: currentColor;
        width: 1.1rem;
        will-change: transform;
    }

    .heartIcon {
        color: var(--color--brand-primary-500);
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;

        > svg {
            height: 1.1rem;
            width: 1.1rem;
        }
    }

    @for $i from 1 through 5 {
        .heartIcon#{$i} {
            transform: translate(-50%, -50%) scale(math.div(math.random(10), 10));
        }
    }

    .rootSelected {
        color: colors.$text-color--red;

        .addToListButtonIcon {
            fill: currentColor;
        }
    }

    .rootAdding {
        color: colors.$text-color--red;

        .addToListButtonIcon {
            fill: currentColor;

            :global {
                animation: pulseHeart 0.6s cubic-bezier(0.04, 0.4, 0.5, 0.95) forwards;
            }
        }

        @for $i from 1 through 5 {
            .heartIcon#{$i} {
                animation: heartAnimate-#{$i} 1.2s cubic-bezier(0.04, 0.4, 0.5, 0.95) forwards;
                opacity: 1;
            }
        }
    }

    .rootRemoving {
        color: colors.$text-color--primary;

        .addToListButtonIcon {
            fill: none;
            stroke: colors.$text-color--primary;

            :global {
                animation: pulseHeart 0.6s cubic-bezier(0.04, 0.4, 0.5, 0.95) 1 forwards;
            }
        }
    }
    @for $i from 1 through 5 {
        @keyframes heartAnimate-#{$i} {
            from {
                opacity: 0.8;
            }

            to {
                opacity: 0;
                transform: translateY((math.random(50) - 25px) * $i) translateX((math.random(50) - 25px) * $i) scale(math.div(math.random(10), 10));
            }
        }
    }
}
