@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/z-index' as zIndex;

.infoPanelWrapper {
    background-color: colors.$brand-color--tertiary;
    min-height: 2rem;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    z-index: zIndex.$index--header-top-bar;

    @include mixins.no-print();

    @include responsive.media('<=tablet') {
        position: sticky;
        top: 0;

        &Invisible {
            top: -20rem;
        }
    }
}

.infoPanelWrapperSearchOpen {
    composes: infoPanelWrapper;
    display: none;
}
