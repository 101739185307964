@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';

.root {
    display: grid;
    gap: 2rem;
    width: 100%;
}

.defaultShipping {
    padding-top: 1rem;
}

.checkoutStepSubtitle {
    @include mixins.checkoutStepSubtitle();
    font-weight: normal;
}

.subtitleWrapper {
    align-items: center;
    display: flex;
    gap: 0.5rem;
}

.formGroup {
    @include mixins.form-grid();
}

.buttons {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    margin-bottom: 1px;
    padding-top: 1rem;
}

.submitAddressButton {
    font-size: 1rem;
}

.ButtonsContainer {
    align-items: center;
    column-gap: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: start;
}

.placeholder {
    position: relative;

    input {
        &::placeholder {
            color: var(--color--gray-700);
            font-size: 0.75rem;
            font-style: italic;
            line-height: 1;
            padding-bottom: 5px;
            top: 0;
        }

        &:placeholder-shown {
            padding-top: 0;
        }
    }
}
