@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.root {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    pointer-events: auto;
    text-align: center;
}
