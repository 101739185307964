@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use './utils';

.table {
    border: 1px solid colors.$border-color--tertiary;
    border-radius: variables.$border-radius;
    display: grid;
    overflow: hidden;
}

.row {
    @include mixins.heading-4();
    background-color: colors.$brand-color--quaternary;
    border-bottom: 1px solid colors.$border-color--tertiary;
    display: none;
    gap: 0.75rem;
    padding: 0.75rem;

    @include responsive.media('>=tablet') {
        display: grid;
        grid-template-columns: utils.$desktopRowTemplate;
    }
}

.cell {
    text-align: left;

    &Center {
        text-align: center;
    }
}
