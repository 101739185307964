@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.storeSwitcherForm {
    @include mixins.form-grid();
}

.storeSwitcherFields {
    @include mixins.form-grid();
}

.storeSwitcherFieldsButtonsWrapper {
    display: grid;
    gap: 1rem;
}

.storeSwitcherFieldsFlag {
    border: 2px solid colors.$color--white;
    border-radius: 50%;
}
