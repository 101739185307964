@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.root {
    display: grid;
    gap: 1.5rem;
}

.mailIcon {
    margin-inline: auto;
    stroke-width: 1;
}

.text {
    color: colors.$text-color--gray;
    margin-bottom: 1rem;
    text-align: center;
}

.title {
    @include mixins.heading-1();
    text-align: center;
}
