@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    align-items: center;
    display: grid;
    font-size: 0.875rem;
    gap: 1rem;
    opacity: 1;
    padding: 1rem;
    transition: opacity 0.2s, visibility 0.2s;
    visibility: visible;

    + .root {
        border-top: 1px solid colors.$border-color--light;
    }

    @include responsive.media('>phone-xl', '<=desktop') {
        grid-template-columns: 4rem 1fr minmax(auto, 7rem);
    }

    @include responsive.media('>tablet') {
        grid-template-columns: auto;
    }

    @include responsive.media('>desktop-xl') {
        grid-template-columns: minmax(4rem, auto) 1fr minmax(auto, 5rem) minmax(auto, 7rem);
    }

    img {
        margin: 0 auto;
    }
}

.rootHidden {
    height: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden;
}

.itemName {
    @include mixins.heading-4();

    text-transform: none;
}

.itemPrice {
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: fonts.$font-weight--medium;
    text-align: right;
}

.itemQuantity {
    display: none;

    @include responsive.media('>=tablet') {
        display: block;
    }
}

.imageLoaded {
    position: absolute;
    top: 0;

    @include responsive.media('>desktop-xl') {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
