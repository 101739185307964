@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.root {
    font-size: 0.875rem;
    padding-bottom: 0.5rem;

    h2 {
        display: none;
    }

    h3 {
        font-size: 1rem;
        font-weight: vars.$font-weight--medium;
        text-transform: uppercase;
    }

    > div > div {
        margin: 0;
        padding: 0 !important;
    }

    :global {
        .row-contained-Oxp.row-root-L38 {
            margin: 0 !important;
        }
    }
}
