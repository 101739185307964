@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';

.wrapper {
    align-items: center;
    display: flex;
    gap: 0.2rem;
}

:global(#hh) {
    .button {
        border: 1px solid colors.$border-color--secondary;
        border-radius: variables.$border-radius;
        display: grid;
        font-size: 1.5rem;
        height: 2rem;
        padding: 0;
        place-items: center;
        width: 2rem;

        &:focus:not(:focus-visible) {
            outline: none;
        }

        &:hover span {
            border-color: colors.$border-color--dark;
        }

        span {
            display: grid;
            place-items: center;
        }

        svg {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.buttonDisabled {
    composes: button;
    border-color: colors.$color--gray-light;
    color: colors.$color--gray;
    cursor: not-allowed;
    pointer-events: none;

    &:hover span {
        border: none;
    }
}

.input {
    font-weight: fonts.$font-weight--medium;
    height: 2rem;
    text-align: center;
    width: 2rem;
}
