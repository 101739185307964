@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.root {
    display: grid;
    gap: 1.5rem;
    margin: 5vh auto 2rem;
    padding: 1rem 1rem 4rem;
    width: min(32rem, 100%);
}

.container {
    @include mixins.form-grid();
}

.heading {
    @include mixins.heading-1();
    text-align: center;
}

.description,
.successMessage {
    color: colors.$text-color--gray;
    margin-bottom: 1rem;
    text-align: center;
}

.invalidToken {
    text-align: center;
}

.errorMessage {
    margin-bottom: 2rem;
}

.grecaptchaBadge {
    height: 0;
    visibility: hidden;
}
