@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    display: grid;
    gap: 1rem;
}

.content {
    composes: root;
    grid-template-areas:
        'description'
        'entered'
        'form-address'
        'recommended'
        'suggested-addresses';

    @include responsive.media('>mobile') {
        grid-template-areas:
            'description description'
            'recommended entered'
            'suggested-addresses form-address';
        grid-template-columns: 1fr 1fr;
    }
}

.contentNoSuggestions {
    composes: root;
    grid-template-areas:
        'description'
        'entered'
        'form-address';
}

.description {
    grid-area: description;
}

.entered {
    @include mixins.heading-5();
    grid-area: entered;
}

.recommended {
    @include mixins.heading-5();
    grid-area: recommended;
}

.entered,
.recommended {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 1rem;
}
