@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'src/styles/resources/z-index' as zIndex;
@use 'src/styles/resources/responsive';

.root {
    display: grid;
    grid-row-gap: 1rem;
    margin-bottom: 1rem;

    /* Display in center */
    min-width: 100%;
    position: fixed;

    /* Show toasts at the top of the screen */
    top: 0;

    /* Necessary to display over page contents */
    z-index: zIndex.$index--toast-container;

    @include responsive.media('>tablet', 'pointer-fine') {
        min-width: auto;
        right: 2rem;
        top: 2rem;
    }
}
