@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.wrapper {
    align-items: center;
    display: flex;
}

.root {
    height: 1.625rem;
    width: 3.25rem;

    @include responsive.media('>tablet') {
        height: 2rem;
        width: 3.75rem;
    }
}
