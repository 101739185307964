@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';

.countryPhoneHeading {
    font-size: 1rem;
    font-weight: vars.$font-weight--medium;
    text-transform: uppercase;
}

.countryPhoneDescription {
    font-size: 0.875rem;
    padding-bottom: 1.5rem;
}

.countryPhoneTableCaption {
    display: none;
}

.countryPhoneTable {
    border: 1px solid colors.$border-color--tertiary;
    table-layout: fixed;
    width: 100%;
}

.countryPhoneTableHead {
    background-color: colors.$color--gray-lightest;
    text-transform: uppercase;
}

.countryPhoneTableCell,
.countryPhoneTableHeadCell {
    font-size: 0.875rem;
    padding: 1rem 0;
    text-align: center;
}

.countryPhoneTableCell {
    border-top: 1px solid colors.$border-color--tertiary;
}
