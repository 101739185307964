@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'src/styles/resources/colors';
@use 'src/styles/resources/variables';
@use 'src/styles/resources/mixins';

.root {
  align-items: center;
  animation: fadeIn 160ms ease-in-out, fadeIn 160ms ease-in-out 4.84s reverse;
  background-color: colors.$color--white;
  border: 1px solid colors.$notification-text-color--default;
  border-radius: variables.$border-radius;
  box-shadow: variables.$toast--box-shadow;
  color: colors.$notification-text-color--default;
  display: grid;
  font-size: 1rem;
  gap: 1rem 1rem;
  grid-auto-columns: min-content auto min-content;
  grid-template-areas: 'icon message controls';
  justify-items: start;
  margin: 0 auto;
  padding: 0.75rem 1rem;
  width: 20rem;
}

.icon {
  grid-area: icon;
  height: 1.5rem;
  width: 1.5rem;
}

.errorToast {
  composes: root;
  border-color: colors.$notification-text-color--error;
  color: colors.$notification-text-color--error;
}

.defaultToast {
  composes: root;
}

.successToast {
  composes: root;
  border-color: colors.$notification-text-color--success;
  color: colors.$notification-text-color--success;
}

.message {
  display: flex;
  font-size: 0.875rem;
  grid-area: message;

  /* For wrapping...*/
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  -ms-word-break: break-all;

  word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-word;
  /* Instead use this non-standard one: */
  word-wrap: break-word;
}

.actions {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
}

.controls {
  grid-area: controls;
}

.actionButton {
  @include mixins.link-underline();
}

.dismissButton {
  color: colors.$notification-icon-color--default;
  @include mixins.trigger-icon();
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
