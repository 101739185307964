@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/utils/white-header' as whiteHeader;

.zoom {
    align-items: center;
    display: flex;

    svg {
        height: 1.25rem;
        stroke: colors.$color--white;
        width: 1.25rem;
    }
}

.label {
    color: colors.$color--white;
    display: none;
}

@include responsive.media('>desktop', 'pointer-fine') {
    .zoom {
        display: none;
    }

    .label {
        clip: auto;
        display: block;
        font-size: 0.75rem;
        height: auto;
        margin-left: 0.5rem;
        overflow: visible;
        padding: 0;
        position: static;
        text-transform: capitalize;
        white-space: normal;
        width: auto;
    }
}

.visuallyHidden {
    @include mixins.a11y-visually-hidden();
    margin: 0;
}

// White design header
@include whiteHeader.white-header-media {
            .zoom {
                color: var(--color--gray-800, colors.$color--mine-shaft);

                svg {
                    stroke: currentColor;
                }
            }
}
