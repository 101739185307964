@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';

.statusBox {
  color: colors.$color--white;
  padding: 0.1rem 0.5rem;
  text-transform: uppercase;
  width: fit-content;

  &Active {
    @extend .statusBox;
    background: colors.$color--dell;
  }

  &Disabled {
    @extend .statusBox;
    background: colors.$color--alizarin-crimson;
  }
}
